/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Image, Text, Button, Fullmap, FullmapWrap, SeparateLine, SeparateLineWrap } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Nemovisti na prodej"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="pb--50 pt--40" style={{"backgroundColor":"#f5f8fd"}} name={"ght4l56i4kt"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"<span style=\"color: var(--black);\">Nemovitosti na prodej</span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column style={{"paddingTop":35,"backgroundColor":"rgba(255,255,255,1)","paddingBottom":105}} name={"vmftil57cn8"}>
          
          <ColumnWrap className="column__flex --left el--3" columns={"3"}>
            
            <ColumnWrapper className="--shape4 js-anim  --anim2 pl--06 pr--06" style={{"backgroundColor":"rgba(245,248,253,1)"}} anim={"2"}>
              
              <Image className="--shape3" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/37752/7fc46e1a965144f7956f8d2b0874724b_s=860x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":""}} srcSet={"https://cdn.swbpg.com/t/37752/7fc46e1a965144f7956f8d2b0874724b_s=350x_.jpg 350w, https://cdn.swbpg.com/t/37752/7fc46e1a965144f7956f8d2b0874724b_s=660x_.jpg 660w, https://cdn.swbpg.com/t/37752/7fc46e1a965144f7956f8d2b0874724b_s=860x_.jpg 860w, https://cdn.swbpg.com/t/37752/7fc46e1a965144f7956f8d2b0874724b_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/37752/7fc46e1a965144f7956f8d2b0874724b_s=2000x_.jpg 2000w"} position={null}>
              </Image>

              <Title className="title-box fs--20 lh--12" style={{"marginTop":21}} content={"Rodinný dům ve Velké Hraštici – Perfektní bydlení blízko Prahy<br>"}>
              </Title>

              <Text className="text-box fs--14" style={{"maxWidth":467}} content={"Krásný rodinný dům z roku 2017 v obci Velká Hraštice, jen kousek od Prahy, čeká na svého nového majitele. Ideální pro rodinné bydlení v klidné lokalitě. Přijďte se přesvědčit na vlastní oči!<br>"}>
              </Text>

              <Button className="btn-box btn-box--pbtn2 btn-box--shape4 fs--16" style={{"marginTop":15}} content={"Zobrazit detail"} use={"page"} href={"/detail"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper className="--shape4 js-anim  --anim2 pl--06 pr--06" style={{"backgroundColor":"rgba(245,248,253,1)"}} anim={"2"}>
              
              <Image className="--shape3" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/37752/c40bd32fed764c539f33e0bda426e10a_s=860x_.png"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":""}} srcSet={"https://cdn.swbpg.com/t/37752/c40bd32fed764c539f33e0bda426e10a_s=350x_.png 350w, https://cdn.swbpg.com/t/37752/c40bd32fed764c539f33e0bda426e10a_s=660x_.png 660w, https://cdn.swbpg.com/t/37752/c40bd32fed764c539f33e0bda426e10a_s=860x_.png 860w, https://cdn.swbpg.com/t/37752/c40bd32fed764c539f33e0bda426e10a_s=1400x_.png 1400w"} position={null}>
              </Image>

              <Title className="title-box fs--20" style={{"marginTop":21}} content={"Nemovitost v přípravě<br>"}>
              </Title>

              <Text className="text-box fs--14" style={{"maxWidth":467}} content={"Již brzy v nabídce! Připravujeme pro Vás exkluzivní novostavbu kousek za Prahou. Nemovitost je ve finální fázi dokončovacích prací a bude brzy k dispozici. Sledujte naši nabídku a buďte mezi prvními, kdo se o této jedinečné příležitosti dozví!<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--shape4 js-anim  --anim2 pl--06 pr--06" style={{"backgroundColor":"rgba(245,248,253,1)"}} anim={"2"}>
              
              <Image className="--shape3" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/23336/fb2e7982928c4ebe9eedffd29dc2c811_s=860x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":""}} srcSet={"https://cdn.swbpg.com/t/23336/fb2e7982928c4ebe9eedffd29dc2c811_s=350x_.jpg 350w, https://cdn.swbpg.com/t/23336/fb2e7982928c4ebe9eedffd29dc2c811_s=660x_.jpg 660w, https://cdn.swbpg.com/t/23336/fb2e7982928c4ebe9eedffd29dc2c811_s=860x_.jpg 860w, https://cdn.swbpg.com/t/23336/fb2e7982928c4ebe9eedffd29dc2c811_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/23336/fb2e7982928c4ebe9eedffd29dc2c811_s=2000x_.jpg 2000w"} position={null}>
              </Image>

              <Title className="title-box fs--20" style={{"marginTop":21}} content={"<span style=\"color: var(--color-blend--15);\">Prodej bytu 3+1 78 m² \nnáměstí Osvoboditelů, Praha&nbsp; 7 999 000,- Kč<br></span><span style=\"color: rgb(129, 25, 25);\">(Prodáno)</span>"}>
              </Title>

              <Text className="text-box fs--14" style={{"maxWidth":467}} content={"<span style=\"color: var(--color-blend--50);\">Úspěšně prodáno a předáno! Nemovitost v Radotíně již dělá radost novým majitelům. Celý proces prodeje proběhl podle plánu a musel být sladěn s přesnými termíny, protože prodávající se stěhovali do zahraničí. Díky správnému ocenění, strategii a kvalitní prezentaci se byt prodal přesně dle představ, což prodávajícím zajistilo dostatek času na přípravy k novému životu v zahraničí.</span><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Fullmap className="--style2" name={"e9jd34g191i"}>
          
          <FullmapWrap className="--full">
          </FullmapWrap>

        </Fullmap>


        <SeparateLine className="pb--10 pt--10" name={"enigi1bl9om"}>
          
          <SeparateLineWrap >
          </SeparateLineWrap>

        </SeparateLine>


        <Column style={{"paddingTop":0,"paddingBottom":0}} layout={"l1"} name={"paticka"}>
          
          <ColumnWrap className="column__flex --left el--2" columns={"2"}>
            
            <ColumnWrapper >
              
              <Text className="text-box w--500" style={{"maxWidth":""}} content={"Häusl&amp;Home Reality"}>
              </Text>

              <Text className="text-box fs--16" style={{"maxWidth":"","marginTop":8}} content={" Ke kapslovně 2855/1 Praha 3 130 00\n"}>
              </Text>

              <Text className="text-box fs--16" style={{"maxWidth":"","marginTop":8}} content={"<a href=\"tel:+420 724 808 718\" style=\"color: var(--color-blend--05);\">+420 724 808 718\n</a>"}>
              </Text>

              <Text className="text-box fs--16" style={{"maxWidth":"","marginTop":8}} content={"<a href=\"mailto:miroslav.hausl@hauslahome.cz\" style=\"color: var(--color-blend--05);\">miroslav.hausl@hauslahome.cz\n</a>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Text className="text-box text-box--right" content={"<a href=\"/sluzby\">Služby</a><br>"}>
              </Text>

              <Text className="text-box text-box--right" style={{"marginTop":3}} content={"<a href=\"/nemovistinaprodej\">Nemovitosti</a><br>"}>
              </Text>

              <Text className="text-box text-box--right" style={{"marginTop":3}} content={"<a href=\"/kontakt\">Kontakt</a><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}